@import "./_colours";
@import "./_variables";

@mixin afi-card {
    background-color: $afi-white;
    box-shadow: $default-shadow;
    color: $afi-slate;
    border-radius: $afi-card-radius;
}

@mixin focus($outline: initial, $text-decoration: none, $font-weight: inherit) {
  &:focus-visible, &:focus {
    outline: $outline;
    text-decoration: $text-decoration;
    font-weight: $font-weight;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
    text-decoration: inherit;
    font-weight: inherit;
  }
}

@mixin focus-not-visible {
  &:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }
}

@mixin offer-logo-thumbnail {
  width: 80px;
  height: 80px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    max-width: 100%;
    max-height: 100%;
    width: 80px;
  }
}
