@import "../styles/_colours";
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.policy-background {
    background-repeat: no-repeat;
    background-position: top;
    background-color: $afi-white;
    background-size: 80rem;
  
    @include media-breakpoint-up(sm) {
      background-size: 104rem;
    }
  
    &--dog {
    background-image: url(/assets/img/policy-bg-dog.jpg);
    background-position: top 0px right -170px;
  
        @include media-breakpoint-up(sm) {
        background-position: top 0px right -250px;
        }
    
        @include media-breakpoint-up(xl) {
        background-position: top;
        }
    }

    &--horse, &--rider {
        background-image: url(/assets/img/policy-bg-horse.jpg);
        background-position: top 0px right -145px;
    
        @include media-breakpoint-up(sm) {
        background-position: top 0px right -180px;
        }
    
        @include media-breakpoint-up(xl) {
        background-position: top;
        }
    }
    
    &--cat {
        background-image: url(/assets/img/policy-bg-cat.jpg);
        background-position: top 0px right -145px;
    
        @include media-breakpoint-up(sm) {
        background-position: top 0px right -180px;
        }
    
        @include media-breakpoint-up(xl) {
        background-position: top;
        }
    }
}