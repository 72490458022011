@import 'styles/afi-bootstrap';
@import 'styles/sizing-extensions';
@import 'styles/policy';
@import 'styles/typography';
@import 'styles/layout';
@import 'styles/multi-step-form';

@import '~@ng-select/ng-select/themes/default.theme.css';

.mid-pink-text {
  color: $afi-mid-pink;
}

@import 'styles/tooltip';

.toast-body {
  background-color: #e3f6e8;
}

.popupClass {
  max-height: 22rem !important;
  overflow-y: auto !important;
}

ngb-datepicker {
  .ngb-dp-header {
      background-color: var(--light);
  }

  select {
    @extend .form-select
  }
  .ngb-dp-weekdays{
    background-color: var(--light);
  }

  .ngb-dp-weekday {
    color: #5bc0de;
    color: var(--info);
  }
}

ngb-pagination{
  .visually-hidden{
    display: none;
  }
}