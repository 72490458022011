
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import "./colours";
@import "./variables";

/* table overrides */
$table-cell-padding-y: .75rem;
$table-cell-padding-x: .75rem;

/* checkbox and radio variable overrides */
$form-check-input-checked-bg-color: $afi-pink;
$form-check-input-checked-border-color: $afi-pink;
$form-check-input-checked-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#ffffff" d="M173.9 439.4l-166.4-166.4c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 312.7 432.1 72.6c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2l-294.4 294.4c-10 10-26.2 10-36.2 0z"/></svg>');
$form-check-radio-checked-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#ffffff" d="M173.9 439.4l-166.4-166.4c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 312.7 432.1 72.6c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2l-294.4 294.4c-10 10-26.2 10-36.2 0z"/></svg>');
$form-check-label-cursor: pointer;
$form-check-transition: transform 0.2s ease, opacity 0.2s ease;
$form-check-input-active-filter: none;

/* progress bar overrides */
$progress-height: 7px;
$progress-bar-bg: $afi-pink;
$progress-bg: #d0d0d0;
$progress-border-radius: 4px;
$progress-bar-transition: 0.5s all;

.progress-bar {
  border-radius: 4px;
}

@import 'bootstrap/scss/variables';

$body-color: $afi-slate;
$link-color: $afi-pink;
$link-hover-color: $afi-darken-pink;
$theme-colors: ( 
  "primary": $afi-pink,
  "secondary": $afi-blue,
  "dark": $afi-slate,
  "danger": $afi-red,
  "success": $afi-success,
  "lifetime": $afi-policy-dark-purple,
  "lifetime-secondary": $afi-policy-light-purple,
  "accident": $afi-policy-dark-blue,
  "accident-secondary": $afi-policy-light-blue,
  "time-limited": $afi-policy-dark-brown,
  "time-limited-secondary": $afi-policy-light-yellow,
  "max-benefit": $afi-policy-dark-green,
  "max-benefit-secondary": $afi-policy-light-green
  );

$font-family-base: $font-body;
$card-border-radius: 20px;
$card-content-border-radius: 10px;
$card-color: $afi-darkest;
$card-cap-color: $afi-darkest;
$enable-rfs: false;
$btn-disabled-opacity: 0.8;

@import "bootstrap";

.table {
  color: $afi-slate;

  tr:first-child{
    th, td{
      border-top: none;
    }
  }
}

.btn {
  border-radius: 0;
}

.btn-primary {
  @include button-variant(
    $background: $afi-pink,
    $border: $afi-pink,
    $color: $afi-white,
    $disabled-color: $afi-white,
    $disabled-background: #979595,
    $disabled-border: 1px solid #979595,
    $hover-color: $afi-white,
    $hover-background: $afi-hover-pink,
    $hover-border: $afi-pink,
  );
}

.btn-secondary {
  @include button-variant(
    $background: $afi-white,
    $border: $afi-pink,
    $color: $afi-pink,
    $hover-color: $afi-white,
    $hover-background: $afi-hover-pink,
    $hover-border: $afi-pink,
    $disabled-color: $afi-pink,
    $active-color: $afi-pink,
  );
}

.btn-tertiary {
  @include button-variant(
    $background: #F9E3EF,
    $border: $afi-pink,
    $color: $afi-pink,
    $hover-color: $afi-white,
    $hover-background: $afi-hover-pink,
    $hover-border: $afi-pink,
    $disabled-color: $afi-pink,
    $active-color: $afi-pink,
  );
}

.btn-outline-primary {
  @include button-outline-variant(
    $color: $afi-pink,
    $color-hover: $afi-white,
    $active-color: $afi-white,
  );
}

.btn-outline-secondary {
  @include button-outline-variant(
    $color: $afi-blue,
    $color-hover: $afi-white,
    $active-color: $afi-white,
  );
}

.btn-decorated {
  border-radius: 0.7rem;

  &.btn-primary {
    box-shadow: 0px 3px 0px #9A004C;
  }

  &.btn-secondary {
    color: #878787;
    border-color: #CBCBCB;
    box-shadow: 0px 3px 0px #878787;
  }

  &.btn-secondary:hover {
    color: white;
  }
}

li.page-item a.page-link {
  color: $link-color;
  transition: background-color 100ms linear;
}
li.page-item.active a.page-link {
  background-color: $link-color;
  border-color: $link-color;
  color: $afi-white;
  z-index: 3;
}

@mixin custom-card($header-color, $content-color) {
  .card {
    border-radius: $card-border-radius;
    box-shadow: 0px 0px 10px rgba(119, 119, 119, 0.6);
  }

  .card.large-screen {
    border-radius: 0rem;
  }
  
  .card-header,
  .card-header-chevron {
    padding: 0;
    border-bottom: 0;
    background: linear-gradient(0deg, white 50%, white 0%);
    font-size: 1.25rem;
    &:first-child {
      @include border-radius($card-border-radius $card-border-radius 0 0);
    }
  }
  
  .card-header > * {
    padding: $spacer;
    background-color: $header-color;
    border-radius: $card-border-radius $card-border-radius 0 $card-border-radius;
    font-weight: 700;
    color: white;
  }

  .card-header-chevron {
    $chevron-header-height: 90px;
    min-height: $chevron-header-height;
    max-height: $chevron-header-height;

    + .card-body {
      background: $content-color;

      &:not(:has(+.card-footer)),
      &:has(+.card-footer:empty) {
        background: linear-gradient(180deg, $content-color 50%, white 0%);
      }
    }

    > * {
      padding: $spacer;
      background-color: $header-color;
      border-radius: $card-border-radius $card-border-radius 0 $card-border-radius;
      font-weight: 700;
      color: white;

      &:first-child {
        padding: 0.5rem $spacer;
        background-color: $header-color;
        border-radius: $card-border-radius 0 0 $card-border-radius;
        min-width: 102px;
        @media (min-width: 431px) {
          min-width: 40%;
        }
      }

      &:last-child {
        padding: 0.5rem $spacer;
        background-color: $content-color;
        color: $afi-darkest;
        border-radius: 0 $card-border-radius 0 0;
        width: 100%
      }

      $chevron-arrow-size: 10px;
      $chevron-width: 10px;

      &.chevron {
        padding: 2px;
        background-color: white;
        border-radius: 0 0 0 0;
        width: $chevron-width;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          right: calc($chevron-arrow-size * -1);
          bottom: 0;
          width: 0;
          height: 0;
          border-left: $chevron-arrow-size solid white;
          border-top: calc($chevron-header-height / 2) solid transparent;
          border-bottom: calc($chevron-header-height / 2) solid transparent;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: $chevron-arrow-size solid $header-color;
          border-top: calc($chevron-header-height / 2) solid transparent;
          border-bottom: calc($chevron-header-height / 2) solid transparent;
        }
      }
    }
  }
  
  .card-body {
    padding: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: $card-border-radius;

    &:not(:has(+.card-footer)),
    &:has(+.card-footer:empty) {
      background: linear-gradient(180deg, $header-color 50%, white 0%);
      border-bottom-left-radius: $card-border-radius;
    }
  }

  .card-footer {
    background-color: $content-color;
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
    border-top-color: $content-color;
    padding-top: 1em;

    &:empty {
      display: none;
    }
  }
  
  .card-body > div {
    background-color: white;
    border-radius: 0 $card-border-radius $card-border-radius $card-border-radius;
  }

  .cover-details-card-content {
    background-color: $content-color;
    border-radius: $card-content-border-radius;
  }
  
  .large-card-header {
    background-color: $header-color;
    color: $afi-white;
    padding: $spacer;
    font-weight: 700;
  }
}

@include custom-card($afi-pink, $afi-pale-pink);

.card.lifetime {
  @include custom-card(
    map-get($theme-colors, 'lifetime'),
    map-get($theme-colors, 'lifetime-secondary'));
}

.card.accident {
  @include custom-card(
    map-get($theme-colors, 'accident'),
    map-get($theme-colors, 'accident-secondary'));
}

.card.time-limited {
  @include custom-card(
    map-get($theme-colors, 'time-limited'),
    map-get($theme-colors, 'time-limited-secondary'));
}

.card.max-benefit {
  @include custom-card(
    map-get($theme-colors, 'max-benefit'),
    map-get($theme-colors, 'max-benefit-secondary'));
}

.card.new-comparison {
  @include custom-card($afi-policy-pink, $afi-policy-light-pink);
}

.radio-container {
  box-sizing: border-box;
  height: 100%;
  background: $afi-white;
  border: 0.1rem solid #4A4A4A;
  box-shadow: 0 0 0.5rem #A8A8A8;
  border-radius: 0.7rem;
  padding-left: 3rem;
}

.form-check:has(> input[type=radio]) {
  @extend .radio-container;

  &:has(input:checked){
    border: 0.1rem solid $afi-pink;
  }

  > input.form-check-input
  {
    margin-top: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;

    &:checked {
      border: inset .2rem transparent;
      box-sizing: border-box;
    }

    &:not(:checked) {
      background-color: rgba(185, 185, 185, 0.42);
    }

    ~ .form-check-label {
      position: relative;
      padding: 1rem 1.5rem;
      font-size: 1.2rem;
      line-height: 1.3;
    }
  }

  &[disabled] {
    color: #CACACA;
    background-color: #EAEAEA;
    border: 1px solid #CACACA;
    opacity: 1;
  }
}

.form-check:has(> input[type=checkbox]) {
  &:has(input:checked){
    border: 0.1rem solid $afi-pink;
  }

  > input.form-check-input {
    height: 2rem;
    width: 2rem;

    &:checked {
      border: inset 0.25rem transparent;
      box-sizing: border-box;
    }

    &:not(:checked) {
      background-color: white;
    }

    ~ .form-check-label {
      margin-left: 0.75rem;
    }
  }
}

table.table.table-bottom-border-0 > tbody > tr:last-child {
  > th, td {
    border-bottom: 0;
  }
}

.toast-success {
  border-color: $afi-darken-green;
  
  >.toast-body {
    background-color: $afi-success-green;
  }
}

.toast-warning {
  border-color: $afi-darken-yellow;
  
  >.toast-body {
    background-color: $afi-pale-yellow;
  }
}