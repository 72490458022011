
@import "./variables";

h1, h2, h3, h4 {
    font-family: $font-heading;
    line-height: 1.3;
}

.heading-font {
    font-family: $font-heading;
}

button, .btn {
  font-weight: 700;
}
