@import "../styles/colours";

.form-group {
	text-align: left;
	margin-bottom: 1.5rem;
}

.form-control:focus {
	border-color: $afi-pink !important;
	box-shadow: none !important;
}

.form-control.ng-invalid.ng-touched {
	border-color: red !important;
	box-shadow: none !important;
}

.label-error {
	color: red !important;
}

.validation-error {
	text-align: left !important;
	color: red !important;
}

.btn-link {
    color: $afi-pink;
}

.btn-link:active,
.btn-link:focus:not(:focus-visible) {
  border-color: transparent;
  text-decoration: none;
}

/* Remove arrows for number type input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
