@import './mixins';

.tooltip-modal {
  h3 {
    font-family: 'foco';
  }

  .modal-body {
    padding: 1.75rem;

    a {
      font-weight: 700;
      color: #EA0074;
    }

    .modal-close-button {
      @include focus-not-visible;
    }
  }
}