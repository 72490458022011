@import "./_colours";

$base-animation-speed: 100ms;
$long-animation-speed: 300ms;
$header-image-height: 7rem;
$max-background-width: 1400px;
$max-body-width: 1100px;

$medium-icon: 130%;
$large-icon: 150%;

$loading-icon: 10rem;
$loading-icon-small: 6rem;
$loading-icon-tiny: 3rem;

$font-size-0-9: 0.9rem;
$font-size-1: 1rem;
$font-size-1-25: 1.25rem;
$font-size-2: 2rem;
$font-size-2-5: 2.5rem;
$font-size-3: 3rem;
$font-size-5: 5rem;

$font-heading: 'foco';
$font-body: 'foco';

$default-shadow: 0px 0px 7px 3px $shadow;

$afi-card-radius: 0.5rem;